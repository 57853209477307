import React, { useContext } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { Divider, Footer as FooterWrapper, TextBlock } from '@vgn-medien-holding/vgn-react-components';
import { MenuItem } from '@vgn-medien-holding/vgn-react-components';
import AppContext from '@lib/AppContext';
import { Navigation } from '../../molecules/navigation/Navigation';

const items: MenuItem[] = [
  {
    title: 'FAQ zum Abo',
    url: '/faq',
  },
  {
    title: 'Kontakt Datenschutz',
    url: 'https://www.vgn.at/kontakt-datenschutz',
  },
  {
    title: 'Impressum & Offenlegung',
    url: '/impressum',
  },
];

export const Footer = () => {
  const context = useContext(AppContext);
  const { footer } = context;
  const navigations = footer?.navigation?.items;

  return (
    <FooterWrapper classProps={{ root: 'mt-20 p-4 lg:p-7 bg-black' }}>
      <div className="flex min-h-[400px] flex-col items-center rounded-2xl bg-gradient-to-r from-gray-700 to-gray-840 px-10 text-white">
        <div className="flex w-full flex-wrap gap-8 pt-10 md:justify-between">
          <Image
            src="/assets/logos/tvm/logo.png"
            width={124}
            height={30}
            copyright=""
            alt="Logo"
            classProps={{ container: 'w-auto' }}
          />
          <div className={'pb-15'}>
            <div className={'font-herokid font-medium'}>{navigations?.[0]?.title}</div>
            <TextBlock classProps={{ block: 'text-gray-400 lg:w-[320px] pt-6' }}>
              {navigations?.[0]?.children && navigations?.[0]?.children?.[0]?.title}
            </TextBlock>
          </div>
        </div>

        <Divider classProps={{ root: 'border-gray-500/65 my-4 w-full col-span-2 md:row-start-1' }} />

        <div className="w-full items-center py-6 lg:flex lg:flex-row-reverse lg:justify-between lg:pb-0">
          <Navigation
            classProps={{
              root: '',
              heading: '',
              list: 'flex gap-x-3 gap-y-2 flex-wrap justify-center',
              listItem: 'text-gray-400 text-sm',
            }}
            renderLink={(link) => (
              <a
                href={link.slug || link.url || ''}
                target="_blank"
                rel="noopener noreferrer"
                className="underline decoration-transparent underline-offset-4 transition-colors duration-150 hover:decoration-primary"
              >
                {link.title}
              </a>
            )}
            items={items}
            withHeading={false}
            title="VGN Medien Holding"
          >
            {/* eslint-disable-next-line */}
            <li className={'ot-sdk-show-settings cursor-pointer text-sm text-gray-400'}>Cookie Einstellungen</li>
          </Navigation>

          <div className="mt-6 text-center text-sm text-gray-400 lg:mt-0">© 2024 TV-MEDIA. All rights reserved</div>
        </div>
      </div>
    </FooterWrapper>
  );
};
